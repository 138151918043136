<script lang="ts" setup>
const { me } = storeToRefs(useAuth())
</script>

<template>
  <UCard class="bg-transparent">
    <div class="flex items-center gap-1 h-8 px-2">
      <img
        :src="`/images/market-maker-logos/${me.marketMakerImage}`"
        class="h-4 w-4 rounded-full border border-neutral-600"
        :alt="`${me.marketMakerName} Logo`"
      >
      <div class="text-xs font-semibold">
        {{ me.marketMakerName }}
      </div>
    </div>
  </UCard>
</template>
